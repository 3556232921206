
import React, { useEffect, useState } from 'react'
import PageTitle from '../../Component/PageTitle';
import { Link } from 'react-router-dom';
import { DataTable, DataTableThead } from '../../Component/DataTable';
import { ClientAccountPasswordChange, ClientChangeStatus, ClientGet, ClientGetProfile, ClientPhotoUpdate, ClientProfileUpdate } from '../../Service/Client';
// import moment from 'moment';
import { ClientResponse } from '../../lib/DataSet/Client';
import { CustomModal } from '../../Component/Modal/CustomModal';
import { toast } from 'react-toastify';
import { ErrorMassage } from '../../lib/Config';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import { checkingAuth } from '../../lib/LocalStorage';

type Props = {}

const ProfileView = (props: Props) => {
    const { ClientData } = useSelector((state: RootState) => state.client)
    // const thead: DataTableThead[] = [
    //     {
    //         element: 'Sl',
    //         slug: 'sl'
    //     },
    //     {
    //         element: "Customer",
    //         slug: "customer"
    //     },
    //     {
    //         element: "Phone",
    //         slug: 'phone'
    //     },
    //     {
    //         element: 'Email',
    //         slug: 'email',
    //     },
    //     // {
    //     //     element: 'Join Date',
    //     //     slug: 'joindate'
    //     // },
    //     {
    //         element: 'Status',
    //         slug: 'status'
    //     },
    //     {
    //         element: 'Action',
    //         slug: 'action',
    //         style: {
    //             padding: 10
    //         }
    //     }
    // ]

    const [fristName, setFristName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [mobileNumber, setMobileNumber] = useState<string>("")
    const [userImage, setUserImage] = useState<string>("")
    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")


    const [clientData, setClient] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1)
    const [totalCount, SetTotalCount] = useState<number>(10)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalId, setIsModalId] = useState<ClientResponse & { index: number } | undefined>();
    const [refress, setRefress] = useState<boolean>(false);

    const [matchPassword, setMatchPassword] = useState<boolean>(false);

    const [file, setFile] = useState<File | null>(null);
    // const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const _handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            // Generate a preview URL for the selected image
            const preview = URL.createObjectURL(selectedFile);
            // setPreviewUrl(preview);
            setUserImage(preview);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("85");
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            //Display choosen image preview
            // const preview = URL.createObjectURL(file);
            // setUserImage(preview);

            if (!file) return;
            const formData = new FormData();
            formData.append('file', file);

            // console.log("92", formData);

            ClientPhotoUpdate(formData).then((UploadResponse) => {
                if (UploadResponse && UploadResponse.status) {
                    toast(UploadResponse.massage, { type: toast.TYPE.SUCCESS });
                    setUserImage(UploadResponse.data.url);

                } else {
                    toast(UploadResponse.massage, { type: toast.TYPE.WARNING });
                }
            }).catch(() => {
                toast(ErrorMassage, { type: toast.TYPE.ERROR });
            })
        }
    }

    useEffect(() => {
        ClientGetProfile().then((ClientProfiledata) => {
            if (ClientProfiledata && ClientProfiledata.status && ClientProfiledata.data) {

                setFristName(ClientProfiledata.data.fristName)
                setLastName(ClientProfiledata.data.lastName)
                setMobileNumber(ClientProfiledata.data.mobileNumber)
                setUserImage(ClientProfiledata.data.userImage)
            }
        }).catch(() => {
            toast(ErrorMassage, { type: toast.TYPE.ERROR });
        })
    }, [])

    useEffect(() => {
        newPassword === confirmPassword ? setMatchPassword(true) : setMatchPassword(false)
    }, [confirmPassword])

    // const clientActiveDeactive = (item: ClientResponse & { index: number } | undefined) => {

    //     if (item) {
    //         ClientChangeStatus(item._id, { status: !item.status }).then((data) => {
    //             if (data.status) {
    //                 setClient((clientData: any) => {
    //                     clientData[item.index] = makeProjectTableData({ ...item, status: !item.status })
    //                     return clientData
    //                 })
    //                 setRefress(!refress)
    //                 setIsModalOpen(false)
    //                 toast(data.massage, { type: toast.TYPE.SUCCESS });
    //             } else {
    //                 toast(data.massage, { type: toast.TYPE.ERROR });
    //             }
    //         }).catch(() => {
    //             setIsModalOpen(false)
    //             toast(ErrorMassage, { type: toast.TYPE.ERROR });

    //         })
    //     }
    // }

    // const makeProjectTableData = (item: ClientResponse & { index: number }) => {
    //     return {
    //         sl: `#${item.index + 1}`,
    //         customer: (<>
    //             <img
    //                 src={item.userImage}
    //                 alt="table-user"
    //                 className="img-fluid avatar-xs rounded"
    //                 style={{
    //                     marginRight: 5
    //                 }}
    //             />
    //             <Link
    //                 to="#"
    //                 className="text-body fw-semibold"
    //             >
    //                 {item.fristName} {item.lastName}
    //             </Link>
    //         </>),
    //         phone: item.mobileNumber,
    //         email: item.email,
    //         // joindate: moment(item.createdOn).format('DD-MMM-YYYY'),
    //         status: item.status ? <span className="badge badge-soft-success">Active</span> : <span className="badge badge-soft-danger">Deactive</span>,
    //         action: (
    //             <div style={{ justifyContent: 'space-between' }}>
    //                 <button
    //                     className={`btn btn-outline-${!item.status ? 'success' : 'danger'} btn-xs`}
    //                     style={{
    //                         marginRight: 5
    //                     }}
    //                     onClick={() => {
    //                         setIsModalId({ ...item })
    //                         setIsModalOpen(true);
    //                     }}
    //                 >
    //                     {!item.status ? "Active" : "Deactive"}
    //                 </button>
    //             </div>
    //         )

    //     }
    // }

    const updateProfile = () => {
        ClientProfileUpdate({ fristName, lastName, mobileNumber, userImage }).then((ClientProfiledata) => {
            if (ClientProfiledata && ClientProfiledata.status) {
                toast(ClientProfiledata.massage, { type: toast.TYPE.SUCCESS });
            } else {
                toast(ClientProfiledata.massage, { type: toast.TYPE.WARNING });
            }
        }).catch(() => {
            toast(ErrorMassage, { type: toast.TYPE.ERROR });
        })
    }

    const changePassword = () => {
        if (!matchPassword) {
            toast("Confirm Password mismatch!", { type: toast.TYPE.WARNING });
            return;
        }else if(!oldPassword || !newPassword){
            toast("Old and/or New Password souldn't be empty!", { type: toast.TYPE.WARNING });
            return;
        }

        ClientAccountPasswordChange({ oldPassword, newPassword }).then((ClientAccountPasswordResponse) => {
            if (ClientAccountPasswordResponse && ClientAccountPasswordResponse.status) {
                toast(ClientAccountPasswordResponse.massage, { type: toast.TYPE.SUCCESS });
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setMatchPassword(false)
            } else {
                toast(ClientAccountPasswordResponse.massage, { type: toast.TYPE.WARNING });
            }
        }).catch(() => {
            toast(ErrorMassage, { type: toast.TYPE.ERROR });
        })
    }

    return (
        <div className="container-fluid">
            {/* start page title */}
            <PageTitle
                pageTitle='Manage Profile'
                parent={[{
                    path: '/client',
                    title: 'Client'
                }]}
            />

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-body">
                                <h4 className="mb-3 header-title">View and update profile details</h4>

                                <div className="row mb-3">
                                    <div className="col-md-2">
                                        <div className="col-md-12 text-center">
                                            <img
                                                style={{
                                                    // height: "10%",
                                                    maxWidth: "100%",
                                                    marginTop: "12px",
                                                    borderRadius: "9px",
                                                }}
                                                src={userImage}
                                            />
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <input
                                                id="image"
                                                name="image"
                                                onChange={handleFileChange}
                                                className="form-control"
                                                type="file"
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                First name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Firstname"
                                                value={fristName}
                                                onChange={(e) => setFristName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">
                                                Mobile Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                value={mobileNumber}
                                                onChange={(e) => setMobileNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Lastname"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() => updateProfile()}
                                        >
                                            Update Profile
                                        </button>

                                    </div>
                                </div>
                                <div className="row mt-4 pt-2 border-top">
                                <h4 className="mb-3 header-title">Change your current password</h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Current Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter Current Password"
                                                // value={fristName}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                New Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter New Password"
                                                // value={fristName}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Confirm New Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Confirm New Password"
                                                // value={fristName}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() => changePassword()}
                                        >
                                            Change Password
                                        </button>

                                    </div>
                                </div>




                            </div>{" "}

                        </div>
                        {/* end card-body*/}
                    </div>
                    {/* end card*/}
                </div>
                {/* end col */}
            </div>
            {/* end row */}
            {/* <CustomModal
                type="Danger"
                title={`${!isModalId?.status ? "Active" : "Deactive"} Confirmation`}
                content={`Are you sure you want to ${!isModalId?.status ? "Active" : "Deactive"} this Client?`}
                is_show={isModalOpen}
                onDelete={ClientActiveDeactive}
                onClose={() => {
                    setIsModalOpen(false)
                }}
                onButtonClick={() => {
                    clientActiveDeactive(isModalId)
                }}
            /> */}
        </div>
    )
}
    ;
export default ProfileView;
/* ClientAuth */
export const LoginUrl: string = "client/signin";
export const SignupUrl: string = "client/signup";


/* Customar Section */
export const CustomarGetUrl: string = "client/customer?page="
export const CustomarManageUrl: string = "client/customer/status/"

export const ClientGetUrl = "admin/client/"
export const ClientManageUrl= "admin/client/status/"


export const CategoryUrl = "client/category/"
// export const CategoryManageUrl = "client/category/update/"

export const SubCategoryUrl = "client/sub-category/"


export const PostGetUrl = "client/post/"
export const PostStatusChangeUrl = "client/post/status/"

export const PostCommentGetUrl = "client/post/comment/"
export const PostRepoteGetUrl = "client/post/report/"

export const ComminctionUpdateUrl = "client/post/update-persentage/"
export const ComminctionGetUrl = "client/post/get-persentage/"

export const ProductGetUrl = "client/product/"


export const ApiKeyGetUrl = "client/apikey"

//File Upload
export const FileUpdateUrl = "upload"
//Client Profile
export const ClientGetProfileUrl = "client/profile"
export const ClientProfileUpdateUrl = "client/profile"
export const ClientAccountPasswordChangeUrl = "client/password"
//Customer Post List
export const CustomerPostGetUrl = "client/post/customer/"
//Customer Listed Products
export const CustomerListedProductsGetUrl = "client/product/customer/"
//Customer Purchase Orders
export const CustomerPurchaseOrdersGetUrl = "client/ordered-product/customer/"
//Customer Personal Info
export const CustomerPersonalInfoGetUrl = "client/customer/"

import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageTitle from '../../Component/PageTitle'
import { ProductSingelGet } from '../../Service/MarketPlase'
import { ProductDataType } from '../../lib/DataSet/EcommerceDataType'
import moment from 'moment'
import { CustomerListedProductsGet, CustomerPersonalInfoGet, CustomerPostsGet, CustomerPurchaseOrdersGet } from '../../Service/Customar'
import { CustomerListedProduct, CustomerPersonalInfo, CustomerPost, CustomerPurchaseOrder } from '../../lib/DataSet/Customar'
import { DataTable, DataTableThead } from '../../Component/DataTable'
import { setTokenSourceMapRange } from 'typescript'
import { toast } from 'react-toastify'
import productDefaultImage from '../../images/no-photo.png'

type Props = {}

type TimelineResponseTable = CustomerPost & { index: number }
type ListedProductsResponseTable = CustomerListedProduct & { index: number }
type PurchaseOrderResponseTable = CustomerPurchaseOrder & { index: number }

const CustomerSingelScreen: React.FC<Props> = () => {
    const [timeline, setTimeline] = useState<any[]>([]);
    const [listedProducts, setListedProducts] = useState<any[]>([]);
    const [purchaseOrders, setPurchaseOrders] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1)
    const [showImage, setShowImage] = useState<ProductDataType['productMedia'][0] | null>(null)
    const [totalCount, SetTotalCount] = useState<number>(10)
    const [totalRecordCount, SetTotalRecordCount] = useState<number>(0)
    const [customer, setCustomer] = useState<CustomerPersonalInfo>();

    let { id: customerId } = useParams();

    const theadPosts: DataTableThead[] = [
        {
            element: 'Sl',
            slug: 'sl',
            className: "text-body fw-semibold"
        },
        {
            element: "Title",
            slug: "postTitle",
            className: "table-user text-center"
        },
        {
            element: 'Post Date',
            slug: 'postdate',
            className: "text-body fw-semibold"
        },
        {
            element: 'Visibility',
            slug: 'visibility',
            className: "text-body fw-semibold"
        },
        {
            element: 'Like(s)',
            slug: 'totalLikes',
            className: "text-body fw-semibold"
        },
        {
            element: 'Comment(s)',
            slug: 'totalComments',
            className: "text-body fw-semibold"
        },
        {
            element: 'Report(s)',
            slug: 'totalReports',
            className: "text-body fw-semibold"
        },
        // {
        //     element: 'Status',
        //     slug: 'status'
        // },
        // {
        //     element: 'Action',
        //     slug: 'action'
        // }
    ]

    const theadListedProducts: DataTableThead[] = [
        {
            element: 'Sl',
            slug: 'sl',
            className: "text-body fw-semibold"
        },
        {
            element: "Photo",
            slug: "productImage",
            className: "text-body fw-semibold"
        },
        {
            element: 'Name',
            slug: 'productName',
            className: "text-body fw-semibold"
        },
        // {
        //     element: 'Description',
        //     slug:'productDesc',
        //     className: "text-body fw-semibold description-column"
        // },
        {
            element: 'Category',
            slug: 'category',
            className: "text-body fw-semibold"
        },
        {
            element: 'Subcategory',
            slug: 'subCategory',
            className: "text-body fw-semibold"
        },
        {
            element: 'Price',
            slug: 'price',
            className: "text-body fw-semibold"
        },
        {
            element: 'Stock',
            slug: 'stock',
            className: "text-body fw-semibold"
        }
    ]

    const theadPurchaseOrders: DataTableThead[] = [
        {
            element: 'Sl',
            slug: 'sl',
            className: "text-body fw-semibold"
        },
        {
            element: "Photo",
            slug: "productImage",
            className: "text-body fw-semibold"
        },
        {
            element: 'Product Name',
            slug: 'productName',
            className: "text-body fw-semibold"
        },
        {
            element: 'Vendor',
            slug: 'vendor',
            className: "text-body fw-semibold description-column"
        },
        {
            element: 'Quantity',
            slug: 'quantity',
            className: "text-body fw-semibold"
        },
        {
            element: 'Price',
            slug: 'purchasePrice',
            className: "text-body fw-semibold"
        },
        {
            element: 'Discount',
            slug: 'discount',
            className: "text-body fw-semibold"
        },
        {
            element: 'Order Total',
            slug: 'orderTotal',
            className: "text-body fw-semibold"
        },
        {
            element: 'Payment Mode',
            slug: 'paymentMode',
            className: "text-body fw-semibold"
        }
    ]

    useEffect(() => {
        showCustomerPersonalInfo()
        showCustomerPosts()
    }, [customerId])

    const showCustomerPersonalInfo = () => {
        if (customerId) {
            CustomerPersonalInfoGet(customerId).then((data) => {
                if (data.status && data?.data) {
                    setCustomer(data?.data);
                    // let newArr: any[] = data.data.map((it, index) => makeTimelineTableData({ ...it, index }))
                    // SetTotalRecordCount(newArr.length)
                    // setTimeline(newArr);
                    // SetTotalCount(data.totalPage * 10);
                }
            }).catch(() => {

            })
        } else {

        }
    }

    const showCustomerPosts = () => {
        if (customerId) {
            CustomerPostsGet(customerId).then((data) => {
                if (data.status && data?.data && data.totalPage) {
                    let newArr: any[] = data.data.map((it, index) => makeTimelineTableData({ ...it, index }))
                    SetTotalRecordCount(newArr.length)
                    setTimeline(newArr);
                    SetTotalCount(data.totalPage * 10);
                }
            }).catch(() => {

            })
        } else {

        }
    }

    const showCustomerListedProducts = () => {
        if (customerId) {
            CustomerListedProductsGet(customerId).then((data) => {
                if (data.status && data?.data && data.totalPage) {
                    let newArr: any[] = data.data.map((it, index) => makeListedProductsTableData({ ...it, index }))
                    SetTotalRecordCount(newArr.length)
                    setListedProducts(newArr);
                    SetTotalCount(data.totalPage * 10);
                }
            }).catch(() => {

            })
        } else {

        }
    }

    const showCustomerPurchaseOrders = () => {
        if (customerId) {
            CustomerPurchaseOrdersGet(customerId).then((data) => {
                if (data.status && data?.data && data.totalPage) {
                    let newArr: any[] = data.data.map((it, index) => makePurchaseOrdersTableData({ ...it, index }))
                    SetTotalRecordCount(newArr.length)
                    setPurchaseOrders(newArr);
                    SetTotalCount(data.totalPage * 10);
                }
            }).catch(() => {

            })
        } else {

        }
    }

    const makeTimelineTableData = (item: TimelineResponseTable) => {
        return {
            sl: `#${item.index + 1}`,
            customer: (<>
                <Link
                    to=""
                    className="text-body fw-semibold"
                >
                    {item.postTitle}
                </Link>
            </>),
            postdate: moment(item.createdOn).format('DD-MMM-YYYY'),
            visibility: item.visibility,
            totalLikes: item.likeCount,
            totalComments: item.commentsCount,
            totalReports: item.reportsCount,
            // status: item.status ? <span className="badge badge-soft-success">Active</span> : <span className="badge badge-soft-danger">Blocked</span>,
            // action: (
            //     <div style={{ justifyContent: 'space-between' }}>
            //         <button
            //             className={`btn btn-outline-${!item.status ? 'success' : 'danger'} btn-xs`}
            //             style={{
            //                 marginRight: 5
            //             }}
            //             onClick={() => {
            //                 // setIsModalId({ ...item })
            //                 // setIsModalOpen(true);
            //             }}
            //         >
            //             {!item.status ? "Active" : "Deactive"}
            //         </button>
            //     </div>
            // )
        }
    }

    const makeListedProductsTableData = (item: ListedProductsResponseTable) => {
        return {
            sl: `#${item.index + 1}`,
            productImage: (<>
                {/* <img src={item?.productMedia[0]?.url} title={item?.productMedia[0]?.url} alt={item?.productMedia[0]?.url}
                    className="me-2 rounded-circle"
                /> */}
                <img src={productDefaultImage} title={item?.productMedia[0]?.url}
                    className="me-2 rounded-circle"
                    style={{
                        maxWidth: "72px"
                    }}
                />
            </>),
            productName: item?.productName,
            // productDesc:item?.productDesc,
            category: item?.category?.name,
            subCategory: item?.subcategory?.name,
            price: item?.price,
            stock: item?.stock,
        }
    }

    const makePurchaseOrdersTableData = (item: PurchaseOrderResponseTable) => {
        return {
            sl: `#${item.index + 1}`,
            productImage: (<>
                {/* <img src={item?.product?.productData?.productMedia?.url} title={item?.productMedia[0]?.url} alt={item?.productMedia[0]?.url}
                    className="me-2 rounded-circle"
                /> */}
                <img src={productDefaultImage} title={item?.product?.productData?.productMedia?.url}
                    className="me-2 rounded-circle"
                    style={{
                        maxWidth: "72px"
                    }}
                />
            </>),
            productName: item?.product?.productData?.productName,
            vendor: (<>{item?.vendor?.fristName} {item?.vendor?.lastName}</>),
            quantity: item?.product?.quantity,
            purchasePrice: item?.product?.purchasePrice,
            discount: item?.product?.discount,
            orderTotal: (item?.product?.purchasePrice - item?.product?.discount) * item?.product?.quantity,
            paymentMode: item?.paymentMode,
        }
    }

    return (
        <div className="container-fluid">
            <PageTitle
                pageTitle='Customer'
                parent={[
                    {
                        path: '/customer',
                        title: 'Customer',
                    }
                ]}
            />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 text-end">
                                    {/* <img src={`https://ui-avatars.com/api/?background=random&rounded=true&name=${customer?.fristName} ${customer?.lastName}`} alt={`${customer?.fristName} ${customer?.lastName}`}
                                        className="me-2 rounded-circle"
                                    />
                                    <img src={customer?.image} alt={`${customer?.fristName} ${customer?.lastName}`}
                                        className="me-2 rounded-circle"
                                    /> */}
                                    <img
                                        src={`https://ui-avatars.com/api/?background=random&rounded=true&name=${customer?.fristName} ${customer?.lastName}`}
                                        alt={`${customer?.fristName} ${customer?.lastName}`}
                                        className="me-2 rounded-circle"
                                        style={{
                                            width:"128px",
                                        }}
                                        onError={(e) => {
                                            // Set fallback image if the image URL is broken
                                            (e.currentTarget as HTMLImageElement).src = `https://ui-avatars.com/api/?background=random&rounded=true&name=${customer?.fristName} ${customer?.lastName}`;
                                        }}
                                    />
                                </div>
                                {/* end col */}
                                <div className="col-lg-9">
                                    Personal Information
                                    <h2>{customer?.fristName} {customer?.lastName}</h2>
                                    <h4>Email: <a href={`mailto:${customer?.email}`} target="_blank">{customer?.email}</a></h4>
                                </div>
                                {/* end col */}
                            </div>
                            {/* end row */}

                            <div className="mt-5">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="timeline-tab" data-bs-toggle="tab" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="true"
                                            onClick={showCustomerPosts}
                                        >Timeline</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="uploaded-products-tab" data-bs-toggle="tab" data-bs-target="#uploaded-products" type="button" role="tab" aria-controls="uploaded-products" aria-selected="false"
                                            onClick={showCustomerListedProducts}
                                        >Listed products</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="purchased-products-tab" data-bs-toggle="tab" data-bs-target="#purchased-products" type="button" role="tab" aria-controls="purchased-products" aria-selected="false"
                                            onClick={showCustomerPurchaseOrders}
                                        >Purchased products</button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
                                        {
                                            timeline.length ? (
                                                <DataTable
                                                    thead={theadPosts}
                                                    tboby={timeline}
                                                    changePage={(page) => setPage(page)}
                                                    currentPage={page}
                                                    pageContent={10}
                                                    totalCount={totalCount}
                                                />
                                            ) : (
                                                <>No records found!</>
                                            )
                                        }

                                    </div>
                                    <div className="tab-pane fade" id="uploaded-products" role="tabpanel" aria-labelledby="uploaded-products-tab">
                                        {
                                            listedProducts.length ? (
                                                <DataTable
                                                    thead={theadListedProducts}
                                                    tboby={listedProducts}
                                                    changePage={(page) => setPage(page)}
                                                    currentPage={page}
                                                    pageContent={10}
                                                    totalCount={totalCount}
                                                />
                                            ) : (
                                                <>No records found!</>
                                            )
                                        }
                                    </div>
                                    <div className="tab-pane fade" id="purchased-products" role="tabpanel" aria-labelledby="purchased-products-tab">
                                        {
                                            purchaseOrders.length ? (
                                                <DataTable
                                                    thead={theadPurchaseOrders}
                                                    tboby={purchaseOrders}
                                                    changePage={(page) => setPage(page)}
                                                    currentPage={page}
                                                    pageContent={10}
                                                    totalCount={totalCount}
                                                />
                                            ) : (
                                                <>No records found!</>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end card*/}
                </div>
                {/* end col*/}
            </div>
        </div>
    )
}

export default CustomerSingelScreen
export const ProjectName = "Social One";
export const ProjecTDarkLogo = "/assets/images/logo-dark-2.png";
export const ProjecTLogo = "/assets/images/logo-dark-2.png";
export const ProjecTSmLogo = "/assets/images/logo-sm.png";

export const BASE_URL = "http://68.183.95.204:8000/api/"


export const ErrorMassage = "Something went wrong please try again"



// font Image
export const Basemap_img = require('../images/Basemap_img.png')
export const LogoFont = require('../images/Logo.png')
export const GoogleIcon = require('../images/logos_google_icon.png')
export const Slider1Image = require('../images/slider1.png')
export const FacebookIcon = require('../images/logos_fcbk.png')

export const SclmnAbout = require('../images/sclmn.png')
export const sclbnrAbout = require('../images/sclbnr.png')

export const HomeEdinboro = require('../images/Video/Intro-Short-Video-Edinboro-University.mp4')
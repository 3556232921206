// eslint-disable-next-line no-unused-vars
import React from 'react'
import ContactVisit from '../../../Component/Contact/ContactVisit'
import ContactSocial from '../../../Component/Contact/ContactSocial'

import "./Contact.css"

function ContactFont() {
    return (
        <>
     
            <ContactVisit/>

          
            <ContactSocial/>
        </>
    )
}

export default ContactFont


import { CustomarResponse, CustomerListedProduct, CustomerPersonalInfo, CustomerPost, CustomerPurchaseOrder } from "../lib/DataSet/Customar";
import { ApiResponse, ManageRequest } from "../lib/DataSet/Global";
import { HttpGet, HttpPut } from "../lib/HttpClient";
import { CustomarGetUrl, CustomarManageUrl, CustomerListedProductsGetUrl, CustomerPersonalInfoGetUrl, CustomerPostGetUrl, CustomerPurchaseOrdersGetUrl } from "./Endpoint";

export const CustomarGet = (page: number = 1): Promise<ApiResponse<CustomarResponse[]>> => {
    return HttpGet(CustomarGetUrl + page)
}

export const CustomarChangeStatus = (CustomarId: string, manageRequest: ManageRequest): Promise<ApiResponse> => {
    return HttpPut(CustomarManageUrl + CustomarId, manageRequest)
}

//All posts by customer
export const CustomerPostsGet = (id: string, page: number = 1): Promise<ApiResponse<CustomerPost[]>> => {
    return HttpGet(`${CustomerPostGetUrl}${id}?page=` + page)
}

export const CustomerListedProductsGet = (id: string, page: number = 1): Promise<ApiResponse<CustomerListedProduct[]>> => {
    return HttpGet(`${CustomerListedProductsGetUrl}${id}?page=` + page)
}

export const CustomerPurchaseOrdersGet = (id: string, page: number = 1): Promise<ApiResponse<CustomerPurchaseOrder[]>> => {
    return HttpGet(`${CustomerPurchaseOrdersGetUrl}${id}?page=` + page)
}

export const CustomerPersonalInfoGet = (id: string): Promise<ApiResponse<CustomerPersonalInfo>> => {
    return HttpGet(`${CustomerPersonalInfoGetUrl}${id}`)
}

import { ClientAccountPassword, ClientProfileResponse, ClientResponse } from "../lib/DataSet/Client"
import { ApiResponse, ManageRequest } from "../lib/DataSet/Global"
import { HttpFileUpload, HttpGet, HttpPost, HttpPut } from "../lib/HttpClient"
import { ClientAccountPasswordChangeUrl, ClientGetProfileUrl, ClientGetUrl, ClientManageUrl, ClientProfileUpdateUrl, FileUpdateUrl } from "./Endpoint"

export const ClientGet = (page: number = 1): Promise<ApiResponse<ClientResponse[]>> => {
    return HttpGet(ClientGetUrl + page)
}

export const ClientChangeStatus = (clientId: string, manageRequest: ManageRequest): Promise<ApiResponse> => {
    return HttpPut(ClientManageUrl + clientId, manageRequest)
}

export const ClientGetProfile = (): Promise<ApiResponse<ClientResponse>> => {
    return HttpGet(ClientGetProfileUrl)
}

export const ClientProfileUpdate = (request: ClientProfileResponse  ): Promise<ApiResponse> => {
    return HttpPut(ClientProfileUpdateUrl, request)
}

export const ClientAccountPasswordChange = (request: ClientAccountPassword  ): Promise<ApiResponse> => {
    return HttpPut(ClientAccountPasswordChangeUrl, request)
}

export const ClientPhotoUpdate = (request: FormData  ): Promise<ApiResponse> => {
    console.log(request);
    
    return HttpFileUpload(FileUpdateUrl, request)
    
}